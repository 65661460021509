import React, { useContext } from "react";

import { SebedimContext } from "../../context/Context";

const CategoryName = ({ cat, click, catId }) => {
  const { dil } = useContext(SebedimContext);

  return (
    <div
      onClick={() => click(cat?.id)}
      className={
        catId == cat.id
          ? "bg-[#A0FB00D9]  rounded-xl cursor-pointer py-2 px-5 w-full"
          : "bg-[#ECFFF5] rounded-xl cursor-pointer py-2 px-5 w-full"
      }
    >
      <p
        className={
          catId == cat.id
            ? "text-[#00391a]  text-[15px] font-[outfit-regular]"
            : "text-[#007337] text-[15px] font-[outfit-regular] "
        }
      >
        {dil === "en" ? cat?.name_en : cat?.name_tm}
      </p>
    </div>
  );
};

export default CategoryName;
// #00391a
